import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import { useForgotPasswordMutation } from '../../../slices/apiSlices/otherApi';
import { CircularProgress } from '@mui/material';

function ForgotPassword() {
  const navigate = useNavigate()
  const location = useLocation();
  const [forgotPassword, { isLoading }] =
  useForgotPasswordMutation();
  const [activeForm, setActiveForm] = useState('publisher'); // State to manage which form is active

  // Function to get form type from the URL path
  const getFormTypeFromPath = () => {
    const path = location.pathname.split('/'); // Split the pathname by '/'
    return path.includes('advertiser') ? 'advertiser' 
         : path.includes('publisher') ? 'publisher' 
         : 'accountmanager'; // Default to 'publisher' if no match
  };


  useEffect(() => {

    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.style.height = '88vh';
    }
    const formType = getFormTypeFromPath(); // Determine the form type
    setActiveForm(formType);

  }, [location]);

  const handleSubmit = async (e)=>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get('email'),
    };
    const formData = {
        email : actualData.email,
        type : activeForm
    }
    console.log(formData);
    
if (actualData.email) {
    const res = await forgotPassword(formData); // Pass the token to the verifyEmail function
    console.log(res); // Handle the response
    if(res?.data?.success){
      toast.success(res.data.msg)
      if(activeForm == 'accountmanager'){
        navigate('/admin')
      }else{
        navigate(`/${activeForm}`)
      }
    }
    else{
      toast.error(res.error.data.msg)
    }
  } else {
    console.error('No token found in the URL');
  }
  }




  return (
    <div className="font-body text-white red-gradient" style={{ height: "100vh" }}>
      {
        isLoading && 
        <div style={{position: 'fixed', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', backdropFilter: 'blur(4px)'}}>
        <CircularProgress style={{ color: "#609bbd" }} />
      </div>
      }
      <Navbar/>
      <main className="pt-16 flex justify-center items-center" >

        {<div className="bg-white xl:w-1/3 text-black p-8">
        <h1 className="font-medium text-3xl text-center mb-2">Forgot Password?</h1>

        <div id="publisher-login-form">
              <form id="publisher-form" method="post" onSubmit={handleSubmit}>
                <div className="mb-3 mt-3">
                  <label htmlFor="publisher_email">Email Address</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="email"
                    placeholder='Your Email'
                    id="email"
                    name="email"
                    required
                  />
                  <p><small style={{color: '#444'}}>Enter your email to get a link</small></p>

                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary py-2 font-medium text-white text-xl mr-2"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Send Link&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                </div>
              </form>
            </div>
        </div>}
      </main>
    </div>
  );
}

export default ForgotPassword;
