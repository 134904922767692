import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from './Navbar';
import { useForgotPasswordMutation, useResetPasswordMutation } from '../../../slices/apiSlices/otherApi';
import { CircularProgress } from '@mui/material';

function ResetPassword() {
  const navigate = useNavigate();
  const location = useLocation();
  const [resetPassword, { isLoading }] =
  useResetPasswordMutation();
//   const [activeForm, setActiveForm] = useState('publisher'); // State to manage which form is active
const [token, setToken] = useState(''); 

  // Function to get the token from query parameters
  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search); // Extract query params from URL
    return {
      token: queryParams.get('token'), // Get the token param
    };
  }


  useEffect(() => {

    const mainElement = document.querySelector('main');
    if (mainElement) {
      mainElement.style.height = '88vh';
    }
    const queryParams = getQueryParams();
    if (queryParams.token) {
      setToken(queryParams.token); // Set the token if present in query params
    } else {
      console.error('No token found in the URL');
    }

  }, [location]);

  const handleSubmit = async (e)=>{
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      pass: data.get('pass'),
    };
    console.log(actualData.pass);
    
    const confirmPassword = data.get('confirm-pass');

    // Check if passwords match
    if (actualData.pass !== confirmPassword) {
      toast.error("Passwords do not match!");
      return; // Do not proceed with the API call if passwords don't match
    }
    const formData = {
        password : actualData.pass,
        token: token
    }
    console.log(formData);
    
if (actualData.pass) {
    const res = await resetPassword(formData); // Pass the token to the verifyEmail function
    console.log(res); // Handle the response
    if(res?.data?.success){
      toast.success(res.data.msg)
      if(res.data.type == 'accountmanager'){
        navigate(`/admin`)
      }else{
        navigate(`/${res?.data.type}`)
      }
    }
    else{
      if(res.error.data.success == false){
        toast.error(res.error.data.errors[0].message)
      }
      toast.error(res.error.data.msg)
    }
  } else {
    console.error('No token found in the URL');
  }
}




  return (
    <div className="font-body text-white red-gradient" style={{ height: "100vh" }}>
      {
        isLoading && 
        <div style={{position: 'fixed', top: 0, left: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', backdropFilter: 'blur(4px)'}}>
        <CircularProgress style={{ color: "#609bbd" }} />
      </div>
      }
      <Navbar/>
      <main className="pt-16 flex justify-center items-center" >

        {<div className="bg-white xl:w-1/3 text-black p-8">
        <h1 className="font-medium text-3xl text-center mb-2">Reset Password</h1>

        <div id="publisher-login-form">
              <form id="publisher-form" method="post" onSubmit={handleSubmit}>
                <div className="mb-3 mt-3">
                  <label htmlFor="publisher_email">Enter New Password</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="password"
                    placeholder='New Password'
                    id="pass"
                    name="pass"
                    required
                  />
                </div>
                <div className="mb-3 mt-3">
                  <label htmlFor="publisher_email">Confirm Password</label>
                  <br />
                  <input
                    className="bg-input mt-2"
                    type="password"
                    placeholder='Confirm Password'
                    id="confirm-pass"
                    name="confirm-pass"
                    required
                  />
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn-primary py-2 font-medium text-white text-xl mr-2"
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;Send Link&nbsp;&nbsp;&nbsp;&nbsp;
                  </button>
                </div>
              </form>
            </div>
        </div>}
      </main>
    </div>
  );
}

export default ResetPassword;