import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const apiUrl = process.env.REACT_APP_API_URL;

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl + "/admin/",
  prepareHeaders: (headers, { getState }) => {
    // Access the token from the Redux state
    const data = getState().admin;
    const token = data?.user?.token

    if (token) {
      // Set the Authorization header for every request
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithInterceptor = async (args, api, extraOptions) => {
  let result;

  try {
    // Pre-request logic (like logging, modifying request)
    // console.log('Request started:', args);

    result = await baseQuery(args, api, extraOptions);

    // Post-request logic (like logging, handling responses)
    // console.log('Request completed:', result);

    // Handle custom logic for certain responses (e.g., automatic logout on 401)
    if (result.error && result.error.status === 401) {
      // Perform custom action like logging out
      // api.dispatch(logout());
    }

  } catch (error) {
    console.error('Request failed:', error);
  }

  return result;
};

export const adminApi = createApi({
  reducerPath: "adminApi",
  baseQuery: baseQueryWithInterceptor, // Use the baseQuery with prepareHeaders

  endpoints: (builder) => ({
    registerAdmin: builder.mutation({
      query: ({ formData }) => ({
        url: "accounts/add",
        method: "POST",
        body: formData,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    loginAdmin: builder.mutation({
      query: (admin) => ({
        url: "login",
        method: "POST",
        body: admin,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    getAllAdmin: builder.query({
      query: () => ({
        url: `accounts`,
        method: "GET",
      }),
    }),
    getAdmin: builder.query({
      query: (userId) => ({
        url: `account/${userId}`,
        method: "GET",
      }),
    }),
    changeAdminPassword: builder.mutation({
      query: (actualData) => ({
        url: "change-password",
        method: "POST",
        body: actualData,
      }),
    }),
    getLoggedAdmin: builder.query({
      query: () => ({
        url: `logged-admin`,
        method: "GET",
      }),
    }),
    adminAccountUpdate: builder.mutation({
      query: ({ formData, userId }) => ({
        url: `account/update/${userId}`,
        method: "PATCH",
        body: formData,
        headers: {
          "Content-type": "application/json",
        },
      }),
    }),
    getAllTrackingDomain: builder.query({
      query: () => ({
        url: `tracking_domains/all`,
        method: "GET",
      }),
    }),
    addTrackingDomain: builder.mutation({
      query: (formData) => ({
        url: `tracking_domains/store`,
        body: formData,
        method: "POST",
      }),
    }),
    updateTrackingDomain: builder.mutation({
      query: ({ Id, formData }) => ({
        url: `tracking_domains/${Id}/update/`,
        body: formData,
        method: "PUT",
      }),
    }),
    getTrackingDomain: builder.query({
      query: (Id) => ({
        url: `tracking_domains/view_single_tracking/${Id}`,
        method: "GET",
      }),
    }),
    getConversion: builder.query({
      query: (Id) => ({
        url: `conversions/${Id}`,
        method: "GET",
      }),
    }),
    getAllConversions: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `conversions`,
        method: "GET",
        params: {
          start_date: startDate,
          end_date: endDate,
        },
      }),
    }),
    getAllPermissions: builder.query({
      query: () => ({
        url: `permissions`,
        method: "GET",
      }),
    }),
    getNotifications: builder.query({
      query: ({ limit, offset, status }) => ({
        url: `notifications?limit=${limit}&offset=${offset}&status=${status}`,
        method: "GET",
      }),
    }),
    readNotification: builder.mutation({
      query: (notificationId) => ({
        url: `notifications/read/${notificationId}`,
        method: "PATCH",
      }),
    }),
    readAllNotification: builder.mutation({
      query: () => ({
        url: `notifications/read/all`,
        method: "PATCH",
      }),
    }),
    conversionApprove: builder.mutation({
      query: (id) => (
        console.log(id),
        {
          url: `conversions/approve/${id}/1`,
          method: "PATCH",
          headers: {
            "Content-type": "application/json",
          },
        }),
    }),
    getDashboardData: builder.query({
      query: ({ startDate, endDate, timeUnit }) => {
        return {
          url: `dashboard/${timeUnit}`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    getAllAdvertisersAndAccounts: builder.query({
      query: () => {
        return {
          url: `get_all_advertisers_users`,
          method: "GET",
        };
      },
    }),
    getClicks: builder.query({
      query: ({ startDate, endDate }) => {
        return {
          url: `clicks`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    getPublishersCCDataByOfferId: builder.query({
      query: ({ startDate, endDate, offerId }) => {
        return {
          url: `clicks/${offerId}`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    getySalesByIdData: builder.query({
      query: ({ startDate, endDate, publisherId, offerId }) => {
        return {
          url: `clicks/${offerId}/${publisherId}/subid`,
          method: "GET",
          params: {
            start_date: startDate,
            end_date: endDate,
          },
        };
      },
    }),
    adminGetClickDetails: builder.query({
      query: ({ clickId }) => {
        return {
          url: `click/${clickId}`,
          method: "GET",

        };
      },
    }),
    // ADVERTISERS
    adminAddAdvertiser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: "advertisers/add",
          method: "POST",
          body: formData,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    adminGetAllAdvertiser: builder.query({
      query: () => {
        return {
          url: "advertisers/",
          method: "GET",
        };
      },
    }),
    adminGetAdvertiser: builder.query({
      query: ({ advertiserId }) => {
        return {
          url: `advertisers/edit/${advertiserId}`,
          method: "GET",

        };
      },
    }),
    adminupdateAdvertiser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `advertisers/edit/`,
          method: "PATCH",
          body: formData,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    adminAdvertiserAddUser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: "advertisers/store_advertiser_user",
          method: "POST",
          body: formData,
        };
      },
    }),
    adminAdvertiserGetAllUsers: builder.query({
      query: ({ advertiserId }) => {
        return {
          url: `advertisers/get_advertiser_user/${advertiserId}`,
          method: "GET",
        };
      },
    }),
    adminAdvertiserGetUser: builder.query({
      query: ({ userId }) => {
        return {
          url: `advertisers/get_user/${userId}`,
          method: "GET",
        };
      },
    }),
    adminAdvertiserUpdateUser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `advertisers/user_update`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    //         OFFERS
    adminAddOffer: builder.mutation({
      query: ({ updatedFormData }) => {
        return {
          url: "offers/add",
          method: "POST",
          body: updatedFormData,
        };
      },
    }),

    adminGetOffer: builder.query({
      query: ({ offerId }) => {
        return {
          url: `offers/edit/${offerId}`,
          method: "GET",
        };
      },
    }),

    adminupdateOffer: builder.mutation({
      query: ({ updatedFormData, offerId }) => {
        return {
          url: `offers/edit/${offerId}`,
          method: "PATCH",
          body: updatedFormData,
        };
      },
    }),

    adminGetOffers: builder.query({
      query: ({
        media_type_id,
        verticle_id,
        price_format_id,
        device_id,
        country,
        status,
      }) => {
        const queryParams = new URLSearchParams({
          media_type_id: media_type_id || "",
          verticle_id: verticle_id || "",
          price_format_id: price_format_id || "",
          device_id: device_id || "",
          country: country || "",
          status: status || "",
        });
        return {
          url: `offers/get?${queryParams.toString()}`,
          method: "GET",
        };
      },
    }),
    applicationUpdate: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `offers/application_status_update`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getOfferApplications: builder.query({
      query: ({ offerId, status }) => {
        return {
          url: `offers/offer_application_filter`,
          method: "GET",
          params: {
            status: status,
            offer_id: offerId,
          },
        };
      },
    }),
    uploadAttachment: builder.mutation({
      query: (formData) => {
        return {
          url: `offers/resources/store`,
          method: "POST",
          body: formData,
        };
      },
    }),
    ///   PUBISHER
    adminAddPublisher: builder.mutation({
      query: ({ formData }) => {
        return {
          url: "publisher/add",
          method: "POST",
          body: formData,
        };
      },
    }),
    adminGetAllPublisher: builder.query({
      query: () => {
        return {
          url: `publisher/getall`,
          method: "GET",
        };
      },
    }),
    adminGetPublisher: builder.query({
      query: ({ publisherId }) => {
        return {
          url: `publisher/get/${publisherId}`,
          method: "GET",
        };
      },
    }),
    adminupdatePublisher: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `publisher/update_publisher_account`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    ///users
    adminPublisherAddUser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: "publisher/store_publisher_users",
          method: "POST",
          body: formData,
        };
      },
    }),
    adminPublisherGetAllUsers: builder.query({
      query: ({ publisherId }) => {
        return {
          url: `publisher/get_users/${publisherId}`,
          method: "GET",
        };
      },
    }),
    adminPublisherGetUser: builder.query({
      query: ({ userId }) => {
        return {
          url: `publisher/get_publisher_users/${userId}`,
          method: "GET",
        };
      },
    }),
    adminPublisherUpdateUser: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `publisher/edit`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    adminGetPaymentHistory: builder.query({
      query: ({ userId }) => {
        return {
          url: `publisher/payment-history/${userId}`,
          method: "GET",
        };
      },
    }),
    adminPublisherAddPayment: builder.mutation({
      query: ({ formData }) => {
        return {
          url: "publisher/payment/store",
          method: "PATCH",
          body: formData,
        };
      },
    }),
    adminPublisherDuopPaymentList: builder.query({
      query: () => {
        return {
          url: `publisher/due-payment-list`,
          method: "GET",
        };
      },
    }),
    updateBillingCycle: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `publisher/update-billing-cycle`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    //  ROLES
    adminAddRole: builder.mutation({
      query: ({ rolePayload }) => {
        return {
          url: "roles/add",
          method: "POST",
          body: rolePayload,
        };
      },
    }),
    adminGetAllRole: builder.query({
      query: () => {
        return {
          url: `roles/get`,
          method: "GET",
        };
      },
    }),
    adminGetRole: builder.query({
      query: ({ roleId }) => {
        return {
          url: `roles/edit/${roleId}`,
          method: "GET",
        };
      },
    }),
    adminupdateRole: builder.mutation({
      query: ({ formData }) => {
        return {
          url: `roles/edit/`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getAllActivePublishers: builder.query({
      query: () => {
        return {
          url: `active-publishers-with-accounts`,
          method: "GET",
        };
      },
    }),
    getActiveOffers: builder.query({
      query: () => {
        return {
          url: `active-offers`,
          method: "GET",
        };
      },
    }),
    addCustomOffer: builder.mutation({
      query: (formData ) => {
        return {
          url: `custom-publisher-offer`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getCustomOffer: builder.query({
      query: (selectedPublisher) => {
        console.log(selectedPublisher);
        
        return {
          url: `custom-publisher-offer`,
          method: "GET",
          params: {
            publisher_id: selectedPublisher.publisherId,
            offer_id: selectedPublisher.offerId,
          },
        };
      },
    }),
  }),
  // refetchOnFocus: true, // Refetch data when window regains focus
  // refetchOnReconnect: true,
});

export const {
  useRegisterAdminMutation,
  useLoginAdminMutation,
  useGetAllAdminQuery,
  useGetAdminQuery,
  useChangeAdminPasswordMutation,
  useGetLoggedAdminQuery,
  useAdminAccountUpdateMutation,
  useGetAllTrackingDomainQuery,
  useAddTrackingDomainMutation,
  useUpdateTrackingDomainMutation,
  useGetTrackingDomainQuery,
  useGetAllConversionsQuery,
  useGetConversionQuery,
  useGetAllPermissionsQuery,
  useGetNotificationsQuery,
  useReadNotificationMutation,
  useReadAllNotificationMutation,
  useConversionApproveMutation,
  useGetDashboardDataQuery,
  useGetAllAdvertisersAndAccountsQuery,
  useGetClicksQuery,
  useGetPublishersCCDataByOfferIdQuery,
  useGetySalesByIdDataQuery,
  useAdminGetClickDetailsQuery,
  //Advertiser
  useAdminAddAdvertiserMutation,
  useAdminGetAllAdvertiserQuery,
  useAdminGetAdvertiserQuery,
  useAdminupdateAdvertiserMutation,
  useAdminAdvertiserAddUserMutation,
  useAdminAdvertiserGetAllUsersQuery,
  useAdminAdvertiserGetUserQuery,
  useAdminAdvertiserUpdateUserMutation,
  //offers
  useAdminAddOfferMutation,
  useAdminGetOffersQuery,
  useAdminGetOfferQuery,
  useAdminupdateOfferMutation,
  useApplicationUpdateMutation,
  useGetOfferApplicationsQuery,
  useUploadAttachmentMutation,
  useGetActiveOffersQuery,
  useAddCustomOfferMutation,
  useGetCustomOfferQuery,

  // publisher
  useAdminAddPublisherMutation,
  useAdminGetAllPublisherQuery,
  useAdminGetPublisherQuery,
  useAdminupdatePublisherMutation,
  useAdminPublisherAddUserMutation,
  useAdminPublisherGetAllUsersQuery,
  useAdminPublisherGetUserQuery,
  useAdminPublisherUpdateUserMutation,
  useAdminGetPaymentHistoryQuery,
  useAdminPublisherAddPaymentMutation,
  useAdminPublisherDuopPaymentListQuery,
  useUpdateBillingCycleMutation,
  useGetAllActivePublishersQuery,
  //ROLES
  useAdminAddRoleMutation,
  useAdminGetAllRoleQuery,
  useAdminGetRoleQuery,
  useAdminupdateRoleMutation,
} = adminApi;