import { logout as logoutAdmin } from '../slices/customSlices/adminSlice';
import { logout as logoutPublisher } from '../slices/customSlices/publisherSlice';
import { logout as logoutAdvertiser } from "../slices/customSlices/advertiserSlice";

const tokenExpirationMiddleware = (store) => (next) => async (action) => {
  if (action.type.endsWith('/rejected')) {

    const { error, payload } = action;
    
    if (error.message === "Rejected" && payload.status === 401) {
      alert('Your session is expired. Please log in again');
      // Token expired, dispatch logout actions
      const { pathname } = window.location;
      if (pathname.startsWith('/admin')) {
        await store.dispatch(logoutAdmin());
        window.location.href = '/?who=admin';
      } else if (pathname.startsWith('/advertiser')) {
        await store.dispatch(logoutAdvertiser());
        window.location.href = '/?who=advertiser';
      } else if (pathname.startsWith('/publisher')) {
        await store.dispatch(logoutPublisher());
        window.location.href = '/?who=publisher';
      }
    }
  }
  return next(action);
};

export default tokenExpirationMiddleware;
