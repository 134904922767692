import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminRoutes from './routes/admin/AdminRoutes';
import AdvertiserRoutes from './routes/advertiser/AdvertiserRoutes';
import PublisherRoutes from './routes/publisher/PublisherRoutes';
import PublicRoutes from './routes/PublicRoutes';
import PrivateRoutes from './routes/PrivateRoutes';
import Login from './components/common/Login';
import ErrorPage from './components/common/Errorpage/index';
import useAuthStatus from './hooks/useAuthStatus';
import "./App.css";
import "./custom.scss";
import "./index.css";
import Verify from './components/common/Login/Verify';
import ForgotPassword from './components/common/Login/ForgotPassword';
import ResetPassword from './components/common/Login/ResetPassword';

function App() {
  const { isAdminAuthenticated, isAdvertiserAuthenticated, isPublisherAuthenticated } = useAuthStatus();
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />}>
            <Route index element={<Login />} />
          </Route>
          {/* Verify */}
          <Route path="/verify" element={<Verify />} />
          {/* Reset Password */}
          <Route path="/reset-password" element={<ResetPassword />} />

          {/*    ***** admin     *****    */}
          <Route path="/admin/login" element={<PublicRoutes loginPath="/?who=admin" path="/admin/dashboard" restricted={true} isAuthenticated={isAdminAuthenticated} />} />
          <Route path="/admin/*" element={<PrivateRoutes isAuthenticated={isAdminAuthenticated} component={AdminRoutes} redirectPath="/admin/login/?who=admin" />} />
          <Route path="/admin/forgot-password/" element={<ForgotPassword/>} />


          {/*    ***** advertiser     *****    */}
          <Route path="/advertiser/login" element={<PublicRoutes loginPath="/?who=advertiser" path="/advertiser/dashboard" restricted={true} isAuthenticated={isAdvertiserAuthenticated} />} />
          <Route path="/advertiser/*" element={<PrivateRoutes isAuthenticated={isAdvertiserAuthenticated} component={AdvertiserRoutes} redirectPath="/advertiser/login/?who=advertiser" />} />
          <Route path="/advertiser/forgot-password/" element={<ForgotPassword/>} />

          {/*    ***** publisher     *****    */}
          <Route path="/publisher/login" element={<PublicRoutes loginPath="/?who=publisher" path="/publisher/dashboard" restricted={true} isAuthenticated={isPublisherAuthenticated} />} />
          <Route path="/publisher/*" element={<PrivateRoutes isAuthenticated={isPublisherAuthenticated} component={PublisherRoutes} redirectPath="/publisher/login/?who=publisher" />} />
          <Route path="/publisher/forgot-password/" element={<ForgotPassword/>} />

          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ top: "70px" }}
      />
    </>
  );
}

export default App;
