import React, { useEffect, useState } from 'react'

function Navbar() {
    const [isMenuVisible, setMenuVisible] = useState(false); // State for the toggle menu

    useEffect(() => {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = 'https://adspayingmedia.com/styles.css';
        link.classList.add("loginStyles");
        document.head.appendChild(link);
        document.body.style.paddingTop = '0px';

        return () => {
            document.head.removeChild(link); // Clean up the link when the component is unmounted
          };
      }, []);
  return (
    <div className="bg-my-black text-white font-body">
    <div className="container mx-auto">
      <nav className="md:flex md:justify-between md:items-center py-4">
        <div className="flex justify-between items-center">
          <div className="px-4 cursor-pointer md:hidden">
            <svg
              onClick={() => setMenuVisible(!isMenuVisible)} // Toggle menu visibility
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
          <h1 className="text-2xl font-bold">
            <a href="/">
              <img src="https://adspayingmedia.com/images/adspayingmedia-logo.png" alt="Logo" width={120} />
            </a>
          </h1>
        </div>
        <ul className={`ul-hidden ${isMenuVisible ? '' : 'hidden'} lg:flex`}>
          <li className="mr-4 p-2">
            <a href="https://adspayingmedia.com/">
              <span>Home</span>
            </a>
          </li>
          <li className="mr-4 p-2">
            <a href="https://adspayingmedia.com/publisher.php">
              <span>Publishers</span>
            </a>
          </li>
          <li className="mr-4 p-2">
            <a href="https://adspayingmedia.com/advertiser.php">
              <span>Advertisers</span>
            </a>
          </li>
          <li className="mr-4 p-2">
            <a href="https://adspayingmedia.com/blog">
              <span>Blog</span>
            </a>
          </li>
          <li className="mr-4 p-2">
            <a href="https://adspayingmedia.com/contact-us.php">
              <span>Contact</span>
            </a>
          </li>
        </ul>
        <ul className={`ul-hidden ${isMenuVisible ? '' : 'hidden'} lg:flex`}>
          <li className="mr-4 p-2">
            <a href="#">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                />
              </svg>
            </a>
          </li>
          <li className="mr-4 p-2">
            <a className="btn btn-primary text-sm py-2" href="/">
              &nbsp;&nbsp;&nbsp;&nbsp;Log In&nbsp;&nbsp;&nbsp;&nbsp;
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  )
}

export default Navbar