import React from 'react';
import Navbar from './Navbar';
import { useLocation, useNavigate } from 'react-router-dom'; 
import { useVerifyEmailMutation } from '../../../slices/apiSlices/otherApi';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

function Verify() {
  const [verifyEmail, { isLoading }] =
  useVerifyEmailMutation();
  const location = useLocation();
  const navigate = useNavigate()

  // Function to extract the token from the URL query params
  const getTokenFromUrl = () => {
    const params = new URLSearchParams(location.search); // Extract query parameters
    return params.get('token'); // Get the token value
  };

  const verifyUser = async (e) => {
    e.preventDefault();
    const token = getTokenFromUrl();
    if (token) {
      const res = await verifyEmail({ token }); // Pass the token to the verifyEmail function
      console.log(res); // Handle the response
      if(res?.data?.success){
        toast.success(res.data.msg)
        navigate(`/?who=${res.data.type}`)
      }
      else{
        toast.error(res.error.data.msg)
        navigate('/')
      }
    } else {
      console.error('No token found in the URL');
    }
  };

  return (
    <>
          <style>
        {`
          .vefify-box {
            width: 500px;
            max-width: 100%;
            margin-left: auto;
            margin-right: auto;
          }
            .verify-loader{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            backdrop-filter: blur(2px)
            }

          @media (max-width: 500px) {
            .vefify-box .content {
              padding-left: 40px !important;
              padding-right: 40px !important;
            }
          }
        `}
      </style>
      <Navbar/>
      <main className="my-10">
        {
          isLoading && <div className='verify-loader'>
          <CircularProgress style={{ color: "#609bbd" }} />
        </div>
        }
        <table
          className="vefify-box"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          bgcolor="#ffffff"
          style={{
            borderRadius: '4px',
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            maxWidth: '100%',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <tbody>
            <tr>
              <td height="40"></td>
            </tr>
            <tr
              style={{
                fontFamily:
                  "-apple-system,BlinkMacSystemFont,'Segoe UI','Roboto','Oxygen','Ubuntu','Cantarell','Fira Sans','Droid Sans','Helvetica Neue',sans-serif",
                color: '#4E5C6E',
                fontSize: '14px',
                lineHeight: '20px',
                marginTop: '20px',
              }}
            >
              <td
                className="content"
                colSpan="2"
                valign="top"
                align="center"
                style={{ paddingLeft: '90px', paddingRight: '90px' }}
              >
                <table width="100%" cellPadding="0" cellSpacing="0" border="0" bgcolor="#ffffff">
                  <tbody>
                    <tr>
                      <td align="center" valign="bottom" colSpan="2" cellPadding="3">
                        <img alt="AdsPayingmedia" width="90" src="/asset/icons/email.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td height="30"></td>
                    </tr>
                    <tr>
                      <td align="center">
                        <span onClick={verifyUser} style={{ color: '#48545d', fontSize: '22px', lineHeight: '24px' }}>
                          Verify your email address
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td height="24"></td>
                    </tr>
                    <tr>
                      <td height="1" bgcolor="#DAE1E9"></td>
                    </tr>
                    <tr>
                      <td height="24"></td>
                    </tr>
                    <tr>
                      <td align="center">
                        <span style={{ color: '#48545d', fontSize: '14px', lineHeight: '24px' }}>
                          In order to start using your Adspayingmedia account,
                          you need to confirm your email address.
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td height="20"></td>
                    </tr>
                    <tr>
                      <td valign="top" width="48%" align="center">
                        <span>
                          <button
                            href="#"
                            onClick={verifyUser}
                            style={{
                              display: 'block',
                              padding: '15px 25px',
                              backgroundColor: '#2ec4b6',
                              color: '#ffffff',
                              borderRadius: '3px',
                              textDecoration: 'none',
                              width:"100%"
                            }}
                          >
                            Verify Email Address
                          </button>
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td height="20"></td>
                    </tr>
                    <tr>
                      <td align="center">
                        <hr/>
                      </td>
                    </tr>
                    <tr>
                      <td height="20"></td>
                    </tr>
                    <tr>
                      <td align="center">
                        <p style={{ color: '#a2a2a2', fontSize: '12px', lineHeight: '17px', fontStyle: 'italic' }}>
                          If you did not sign up for this account you can ignore this email and the account will be
                          deleted.
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td height="60"></td>
            </tr>
          </tbody>
        </table>
      </main>
    </>
  )
}

export default Verify