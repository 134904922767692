import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import {
	useGetAllConversionsQuery,
	useConversionApproveMutation,
} from "../../../../slices/apiSlices/adminApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatTimeStampDate } from "../../../../utils/Utility";
import useUserData from "../../../../hooks/useUserData";

const Conversions = () => {

	const { adminData } = useUserData();
	const isAdmin = adminData.user.is_super_admin;
	const today = new Date().toISOString().split("T")[0];
	const firstDayOfMonth = new Date(
		new Date().getFullYear(),
		new Date().getMonth(),
		2
	);
	const formattedFirstDayOfMonth = firstDayOfMonth.toISOString().split("T")[0];

	const [startDate, setStartDate] = useState(formattedFirstDayOfMonth);
	const [endDate, setEndDate] = useState(today);

	const [filterText, setFilterText] = useState("");
	const { data, isLoading, isSuccess, refetch, error } =
		useGetAllConversionsQuery({ startDate, endDate });
	const [conversionApprove, { isLoading: updateLoading }] =
		useConversionApproveMutation();

	if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
	if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}

	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}
	const userData = data.data;

	const handleApprove = async (id) => {
		try {
			const res = await conversionApprove(id);

			if (res?.data?.success) {
				toast.success(res.data.message);
				refetch();
			} else {
				toast.error("Error approving conversion.");
			}
		} catch (error) {
			toast.error("Error approving conversion.");
		}
	};

	const formattedData = Array.isArray(userData)
		? userData?.map((user, index) => {
			const createdAt = formatTimeStampDate(user?.createdAt);
			const updatedAt = formatTimeStampDate(user?.updatedAt);
console.log('userrr : ' , user);

			return {
				id: index + 1,
				_id: user._id,
				offer: (
					<Link
						to={`/admin/conversions/${user?._id}`}
						className="profile-view"
					>
						{user?.offer_name}
					</Link>
				),
				panel: user?.panel,
				is_approved: user?.is_approved,
				publisher: (
					<Link
						to={`/admin/publisher-accounts/publishers/userview/${user?.publisher_id}`}
						className="profile-view"
					>
						{user?.publisher_name}
					</Link>
				),
				sub_id: (
					<span className="table-cell">
						{user?.sub_id}
					</span>
				),
				order_id: user?.order_id,
				amount: user?.amount !== undefined ? "$" + user.amount : "$0",
				conversion_type: user?.conversion_type,
				is_verified: user?.is_verified,
				unverified_reason: user?.unverified_reason,
				createdAt: createdAt,
				updatedAt: updatedAt,
				clickDetails: (
					<Link
						to={`/admin/reporting/clicksdetails/${user?.click_id}`}
						className="profile-view"
					>
						View Click Details
					</Link>
				),
			};
		})
		: [];

	const columns = [
		{
			name: "Sr. no",
			selector: (row) => row.id,
			sortable: true,
			width: "5%",
			cell: (row) => <div className="table-cell">{row.id}</div>,
		},
		{
			name: "Offer",
			selector: (row) => row.offer,
			cell: (row) => <div className="table-cell">{row.offer}</div>,
		},
		{
			name: "Publisher",
			selector: (row) => row.publisher,
			cell: (row) => <div className="table-cell">{row.publisher}</div>,
		},
		{
			name: "Is Verified",
			selector: (row) => row.is_verified,
			cell: (row) => (
				<div className="table-cell">
					{row.is_verified === 1 ? (
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="border-0 text-success"
							icon={faCircle}
						/>
					) : row.is_verified === 2 ? (
						<span className="un-tooltip" data-tooltip={row.unverified_reason}>
    <FontAwesomeIcon
        data-toggle="dropdown"
        className="border-0 text-danger"
        icon={faCircle}
    />
</span>
					) : (
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="border-0 text-muted"
							icon={faCircle}
						/>
					)}
				</div>
			),
		},
		{
			name: "Is Approved",
			selector: (row) => row.is_approved,
			cell: (row) => (
				<div className="table-cell">
					{row.is_verified === 2 ? (
						"N/A"
					) : row.is_approved === 1 ? (
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="border-0 text-success"
							icon={faCircle}
						/>
					) : isAdmin && row.is_approved == 0 && row.is_verified == 1 ? (
						<button
							style={{
								backgroundColor: "#4CAF50",
								border: "none",
								color: "white",
								padding: "8px",
								textAlign: "center",
								textDecoration: "none",
								display: "inline-block",
								fontSize: "10px",
								margin: "4px -6px",
								cursor: "pointer",
								borderRadius: "6px",
								boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
								transition: "background-color 0.3s ease",
							}}
							onClick={() => handleApprove(row._id)}
						>
							Click to Approve
						</button>
					) : (
						<FontAwesomeIcon
							data-toggle="dropdown"
							className="border-0 text-muted"
							icon={faCircle}
						/>
					)}
				</div>
			),
		},
		{
			name: "Panel",
			selector: (row) => row.panel,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.panel}</div>,
		},
		{
			name: "Sub Id",
			selector: (row) => row.sub_id,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.sub_id}</div>,
		},
		{
			name: "Order Id",
			selector: (row) => row.order_id,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.order_id}</div>,
		},
		{
			name: "Amount",
			selector: (row) => row.amount,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.amount}</div>,
		},
		{
			name: "Conversion Type",
			selector: (row) => row.conversion_type,
			sortable: true,
			cell: (row) => <div className="table-cell">{row.conversion_type}</div>,
		},
		{
			name: "Creation Date",
			selector: (row) => row.createdAt,
			sortable: true,
			cell: (row) => (
				<div className="table-cell">
					<div>{row.createdAt.date}</div>
					<div>{row.createdAt.time}</div>
				</div>
			),
		},
		{
			name: "Updated Date",
			selector: (row) => row.updatedAt,
			cell: (row) => (
				<div className="table-cell">
					<div>{row.updatedAt.date}</div>
					<div>{row.updatedAt.time}</div>
				</div>
			),
		},
		{
			name: "Click Details",
			selector: (row) => row.clickDetails,
			cell: (row) => <div className="table-cell">{row.clickDetails}</div>,
		},
	];

	// Function to handle search input changes
	const handleFilter = (event) => {
		const value = event.target.value || "";
		setFilterText(value);
	};

	// Apply search filter to the data
	const filteredData = formattedData.filter((item) => {
		const name = item.offer.props.children || "";
		return name.toLowerCase().includes(filterText.toLowerCase());
	});
	const handleStartDateChange = (date) => {
		setStartDate(date);
	};

	const handleEndDateChange = (date) => {
		setEndDate(date);
	};
	return (
		<div id="route-view" className="col-md-12">
			<div>
				<h2 className="main-heading">Conversions</h2>
				<div className="admin-date-filters">
					<input
						name="start_date"
						type="date"
						value={startDate}
						onChange={(event) => handleStartDateChange(event.target.value)}
					/>
					<span>-</span>
					<input
						name="end_date"
						type="date"
						value={endDate}
						onChange={(event) => handleEndDateChange(event.target.value)}
					/>
				</div>
				<div className="table-supplies">
					<div className="top-filters">
						<div className="search-bar w-25 ml-auto">
							<input
								type="text"
								placeholder="Search Offer"
								className="form-control"
								value={filterText}
								onChange={handleFilter}
							/>
						</div>
					</div>
					<div className="dataTable offers-datatable">
						<DataTable
							columns={columns}
							data={filteredData}
							pagination
							highlightOnHover
							fixedHeader
							responsive
							paginationPerPage={10}
							customStyles={{
								tableWrapper: {
									style: {
										height: '400px',
									},
								},
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Conversions;
