import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiUrl = process.env.REACT_APP_API_URL;

export const otherApi = createApi({
  reducerPath: "otherApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiUrl + "/" }),

  endpoints: (builder) => ({
    getAllDevices: builder.query({
      query: () => {
        return {
          url: `devices`,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    getAllVerticles: builder.query({
      query: () => {
        return {
          url: `verticles`,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    getAllPriceFormats: builder.query({
      query: () => {
        return {
          url: `price-formats`,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getAllMediaTypes: builder.query({
      query: () => {
        return {
          url: `media`,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getPredefinedUrl: builder.mutation({
      query: (imageType) => {
        return {
          url: `predefined-url`,
          method: "POST",
          headers: {
            // "Content-type": "application/json",
          },
          body: { mimeType: imageType },
        };
      },
    }),
    getAllCountry: builder.query({
      query: () => {
        return {
          url: `country`,
          method: "GET",
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getAllState: builder.mutation({
      query: (country_code) => {
        return {
          url: `state`,
          method: "POST",
          body: country_code,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getAllCity: builder.mutation({
      query: (codes) => {
        return {
          url: `city`,
          method: "POST",
          body: codes,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getAllTrackingDomain: builder.query({
      query: () => ({
        url: `tracking_domains/all`,
        method: "GET",
      }),
    }),
    uploadAttachment: builder.mutation({
      query: (formData) => {
        return {
          url: `offers/resources/store`,
          method: "POST",
          body: formData,
        };
      },
    }),
    verifyEmail: builder.mutation({
      query: ({token}) => {
        return {
          url: `verify-email?token=${token}`,
          method: "POST",
        };
      },
    }),
    forgotPassword: builder.mutation({
      query: (formData) => {
        return {
          url: `/forgot-password`,
          method: "POST",
          body: formData,
        };
      },
    }),
    resetPassword: builder.mutation({
      query: (formData) => {
        return {
          url: `/reset-password `,
          method: "POST",
          body: formData,
        };
      },
    }),
    // updateImage: builder.mutation({
    //   query: ({ uploadUrl, file }) => ({
    //     url: uploadUrl,
    //     method: 'PUT',
    //     body: file,
    //     headers: {
    //       'Content-Type': file.type,
    //     },
    //   }),
    // }),
  }),
});

export const {
  useGetAllDevicesQuery,
  useGetAllPriceFormatsQuery,
  useGetAllVerticlesQuery,
  useGetAllMediaTypesQuery,
  useGetPredefinedUrlMutation,
  useGetAllCountryQuery,
  useGetAllCityMutation,
  useGetAllStateMutation,
  useGetAllTrackingDomainQuery,
  useUploadAttachmentMutation,
  useVerifyEmailMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation
  // useUpdateImageMutation,
} = otherApi;
