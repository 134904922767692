import React, { useEffect, useState } from "react";
import "../Admin.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from "@mui/material";
import { useAddCustomOfferMutation, useGetActiveOffersQuery, useGetAllActivePublishersQuery, useGetCustomOfferQuery } from "../../../../slices/apiSlices/adminApi";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { toast } from "react-toastify";

function CustomOffer() {
  const {data : offers } = useGetActiveOffersQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const {data : publishers , isLoading, error, isSuccess} = useGetAllActivePublishersQuery(null, {
    refetchOnMountOrArgChange: true,
    refetchOnReconnect: true,
  });
  const [addCustomOffer, { isLoading: AddLoading }] = useAddCustomOfferMutation()
	const [offerDetailsOpen, setOfferDetailsOpen] = useState(true);
	const [offerClicksOpen, setOfferClicksOpen] = useState(true);
  const [revCapsOpen , setRevCapsOpen] = useState(true);
  const [shouldFetchCustomOffer, setShouldFetchCustomOffer] = useState(false);
  const [selectedPublisherId, setSelectedPublisherId] = useState('');
  const [selectedOfferId, setSelectedOfferId] = useState('');
  const [payoutValue , setPayoutValue] = useState(0)
  //payout caps
  const [clickedCapsDaily , setClicksCapsDaily] = useState(0)
  const [clickedCapsWeekly , setClicksCapsWeekly] = useState(0)
  const [clickedCapsMonthly , setClicksCapsMonthly] = useState(0)
  const [clickedCapsGlobal , setClicksCapsGlobal] = useState(0)
  const [convCapsDaily , setConvCapsDaily] = useState(0)
  const [convCapsWeekly , setConvCapsWeekly] = useState(0)
  const [convCapsMonthly , setConvCapsMonthly] = useState(0)
  const [convCapsGlobal , setConvCapsGlobal] = useState(0)
  const [payoutCapsDaily , setPayoutCapsDaily] = useState(0)
  const [payoutCapsWeekly , setPayoutCapsWeekly] = useState(0)
  const [payoutCapsMonthly , setPayoutCapsMonthly] = useState(0)
  const [payoutCapsGlobal , setPayoutCapsGlobal] = useState(0)

  // States for offer checkboxes
    const [checkClickDaily, setCheckClickDaily] = useState(false);
    const [checkClickWeekly, setCheckClickWeekly] = useState(false);
    const [checkClickMonthly, setCheckClickMonthly] = useState(false);
    const [checkClickGlobal, setCheckClickGlobal] = useState(false);
  
    const [checkConvDaily, setCheckConvDaily] = useState(false);
    const [checkConvWeekly, setCheckConvWeekly] = useState(false);
    const [checkConvMonthly, setCheckConvMonthly] = useState(false);
    const [checkConvGlobal, setCheckConvGlobal] = useState(false);
  
    const [checkPayoutDaily, setCheckPayoutDaily] = useState(false);
    const [checkPayoutWeekly, setCheckPayoutWeekly] = useState(false);
    const [checkPayoutMonthly, setCheckPayoutMonthly] = useState(false);
    const [checkPayoutGlobal, setCheckPayoutGlobal] = useState(false);
  
    // States for offer checkboxes
    const [checkRevClicksDaily, setCheckRevClicksDaily] = useState(false);
    const [checkRevClicksWeekly, setCheckRevClicksWeekly] = useState(false);
    const [checkRevClicksMonthly, setCheckRevClicksMonthly] = useState(false);
    const [checkRevClicksGlobal, setCheckRevClicksGlobal] = useState(false);
  
    const [checkRevConvDaily, setCheckRevConvDaily] = useState(false);
    const [checkRevConvWeekly, setCheckRevConvWeekly] = useState(false);
    const [checkRevConvMonthly, setCheckRevConvMonthly] = useState(false);
    const [checkRevConvGlobal, setCheckRevConvGlobal] = useState(false);
  
    const [checkRevAmountDaily, setCheckRevAmountDaily] = useState(false);
    const [checkRevAmountWeekly, setCheckRevAmountWeekly] = useState(false);
    const [checkRevAmountMonthly, setCheckRevAmountMonthly] = useState(false);
    const [checkRevAmountGlobal, setCheckRevAmountGlobal] = useState(false);

// Function to construct offer_caps dynamically based on checked fields
const constructCaps = (capType, daily, weekly, monthly, global, checkDaily, checkWeekly, checkMonthly, checkGlobal) => {
  const caps = {};
  if (checkDaily) caps.daily = Number(daily);
  if (checkWeekly) caps.weekly = Number(weekly);
  if (checkMonthly) caps.monthly = Number(monthly);
  if (checkGlobal) caps.global = Number(global);
  return caps;
};

// Construct formData only with selected fields
const formData = {
  publisher_id: selectedPublisherId,
  offer_id: selectedOfferId,
  payout_value: payoutValue,
  payout_caps: {
    click_caps: constructCaps(
      "click_caps",
      clickedCapsDaily,
      clickedCapsWeekly,
      clickedCapsMonthly,
      clickedCapsGlobal,
      checkClickDaily,
      checkClickWeekly,
      checkClickMonthly,
      checkClickGlobal
    ),
    conversion_caps: constructCaps(
      "conversion_caps",
      convCapsDaily,
      convCapsWeekly,
      convCapsMonthly,
      convCapsGlobal,
      checkConvDaily,
      checkConvWeekly,
      checkConvMonthly,
      checkConvGlobal
    ),
    amount: constructCaps(
      "amount",
      payoutCapsDaily,
      payoutCapsWeekly,
      payoutCapsMonthly,
      payoutCapsGlobal,
      checkPayoutDaily,
      checkPayoutWeekly,
      checkPayoutMonthly,
      checkPayoutGlobal
    )
  },
};

  const publishersList = publishers?.data || [];
  const offersList = offers?.data || [];

  const publishersOptions = [];
  const offersOptions = [];

  useEffect(()=>{
    publishersList.forEach((publisher) => {
			publishersOptions.push({
				color: "#609bbd",
				value: publisher._id,
        label: `${publisher.name} (${publisher.account_details.name})`,
			});
		});
    
  } , [publishersOptions])
  useEffect(()=>{
    offersList.forEach((offer) => {
			offersOptions.push({
				color: "#609bbd",
				value: offer._id,
				label: offer.name,
			});
		});
    
  } , [offersOptions])

    // Effect to trigger existing custom offer fetch only when both selectedPublisherId and selectedOfferId are set
    useEffect(() => {
      if (selectedPublisherId && selectedOfferId) {
        setShouldFetchCustomOffer(true);
      }
    }, [selectedPublisherId, selectedOfferId]);

  // Fetch custom offer only when shouldFetchCustomOffer is true
  const { data: existingCustomOffer } = useGetCustomOfferQuery(
    {
      publisherId: selectedPublisherId,
      offerId: selectedOfferId,
    },
    {
      skip: !shouldFetchCustomOffer, // Skip the query if conditions are not met
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    }
  );

  //fill prefill data
  useEffect(() => {
    if (existingCustomOffer?.data) {
      console.log(existingCustomOffer?.data);
      
      setPayoutValue(existingCustomOffer.data?.payout_value || 0);
      setClicksCapsDaily(existingCustomOffer.data.payout_caps.click_caps?.daily || 0);
      setClicksCapsWeekly(existingCustomOffer.data.payout_caps.click_caps?.weekly || 0);
      setClicksCapsMonthly(existingCustomOffer.data.payout_caps.click_caps?.monthly || 0);
      setClicksCapsGlobal(existingCustomOffer.data.payout_caps.click_caps?.global || 0);
      setConvCapsDaily(existingCustomOffer.data.payout_caps.conversion_caps?.daily || 0);
      setConvCapsWeekly(existingCustomOffer.data.payout_caps.conversion_caps?.weekly || 0);
      setConvCapsMonthly(existingCustomOffer.data.payout_caps.conversion_caps?.monthly || 0);
      setConvCapsGlobal(existingCustomOffer.data.payout_caps.conversion_caps?.global || 0);
      setPayoutCapsDaily(existingCustomOffer.data.payout_caps.payout_caps?.daily || 0);
      setPayoutCapsWeekly(existingCustomOffer.data.payout_caps.payout_caps?.weekly || 0);
      setPayoutCapsMonthly(existingCustomOffer.data.payout_caps.payout_caps?.monthly || 0);
      setPayoutCapsGlobal(existingCustomOffer.data.payout_caps.payout_caps?.global || 0);
    }
  }, [existingCustomOffer]);
  
  const toggleOfferDetails = () => {
		setOfferDetailsOpen(!offerDetailsOpen);
	};
  const toggleClickCaps = () => {
		setOfferClicksOpen(!offerClicksOpen);
	};
  function removeEmpty(obj) {
    // Iterate through the object properties
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
  
      // Check if the value is an object and not null
      if (typeof value === 'object' && value !== null) {
        // Recursively remove empty properties
        removeEmpty(value);
  
        // If the object or array is empty, delete it from the parent object
        if (Object.keys(value).length === 0 || (Array.isArray(value) && value.length === 0)) {
          delete obj[key];
        }
      }
  
      // If the value is null or undefined, delete it from the parent object
      if (value === null || value === undefined) {
        delete obj[key];
      }
    });
  
    return obj;
  }
  
  const handleSubmit = async (e)=>{
    e.preventDefault()

      // Remove empty objects and arrays from formData
  const filteredPayload = removeEmpty({ ...formData });
    // toast.success(res.data.message)
    if(formData.payout_value < 0){
      toast.error('Payout value must be positive')
      return;
    } 
    const res = await addCustomOffer(filteredPayload);
    if(res.error){
      toast.error(res.error.data.message);
      return false
    }
      console.log(res);
     toast.success(res.data.message)
  }
  
  if (isLoading) {
		return (
			<div id="route-view" className="col-md-12">
				Loading...
			</div>
		);
	}
  if (error) {
		return (
			<div id="route-view" className="col-md-12">
				{" "}
				{error?.data?.msg}
			</div>
		);
	}
	if (!isSuccess) {
		return (
			<div id="route-view" className="col-md-12">
				Unable to fetch data.
			</div>
		);
	}

  return (
    <div id="route-view" className="col-md-12 ">
      <h2 className="main-heading">Customize Offer</h2>
      <form className="offer" onSubmit={handleSubmit}>
        {/*
      <div className="form-panel offer">
        <div className="form-submit-bar">
							<div>
								<Link
									to={"/admin/offers"}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
									disabled={AddLoading}
								>
									{AddLoading ? "Adding..." : "Add"}
								</button>
							</div>
						</div>
      </div> */}
        <div className="form-panel offer" >
        <h3
          onClick={toggleOfferDetails}
          className={offerDetailsOpen ? "heading" : "heading collapsed"}
          style={
            offerDetailsOpen
              ? {
                  cursor: "pointer",
                  color: "black",
                  display: "flex",
                  justifyContent: "space-between",
                }
              : {
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }
          }
        >
          <span>Select Publisher</span>
          <i>
            <FontAwesomeIcon
              className="icon"
              icon={offerDetailsOpen ? faMinus : faPlus}
            />
          </i>
        </h3>
        <Collapse in={offerDetailsOpen}>
        <div className="form-group">
              <label className="">Publishers</label>
              <Select
											isMulti={false}
											name="publisher"
											options={publishersOptions}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={(e) => {
												setSelectedPublisherId(e.value)                       
											}}
										/>
            </div>
            <div className="form-group">
              <label className="">Offers</label>
              <Select
											isMulti={false}
											name="offer"
											options={offersOptions}
											className="basic-multi-select"
											classNamePrefix="select"
											onChange={(e) => {
												setSelectedOfferId(e.value)                       
											}}
										/>
            </div>
            <div className="form-group row">
              <div className="col-5">
                <label className="required">Payout Amount</label>
                <div className="input-group w-50">
                  <div className="input-group-prepend">
                    <small className="input-group-text">$</small>
                  </div>
                  <input
                    type="number"
                    placeholder="0.00"
                    className="form-control after"
                    name="payout_value"
                    value={payoutValue}
                    onChange={(e)=>{
                      setPayoutValue(e.target.value)
                    }}
                  />
                </div>
                <small className="form-text info-text"></small>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="form-panel offer">
        <h3
          onClick={toggleClickCaps}
          className={offerClicksOpen ? "heading" : "heading collapsed"}
          style={
            offerClicksOpen
              ? {
                  cursor: "pointer",
                  color: "black",
                  display: "flex",
                  justifyContent: "space-between",
                }
              : {
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }
          }
        >
          <span>Offer Click Caps</span>
          <i>
            <FontAwesomeIcon
              className="icon"
              icon={offerClicksOpen ? faMinus : faPlus}
            />
          </i>
        </h3>
        <Collapse in={offerClicksOpen} className="">
        <div class="form-group">
            <label>Click Caps</label>
            <div class="caps-row">
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="click_capsDaily"
                    onChange={() => setCheckClickDaily(!checkClickDaily)}
                    class="custom-control-input"
                  />
                  <label for="click_capsDaily" class="custom-control-label m-0">
                    Daily
                  </label>
                </div>
                <input
                // disabled={!checkClickDaily}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="daily_caps"
                    value={clickedCapsDaily}
                    onChange={(e)=>{
                      setClicksCapsDaily(e.target.value)
                    }}
                />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="click_capsWeekly"
                    onChange={() => setCheckClickWeekly(!checkClickWeekly)}
                    class="custom-control-input"
                  />
                  <label
                    for="click_capsWeekly"
                    class="custom-control-label m-0"
                  >
                    Weekly
                  </label>
                </div>
                <input
                // disabled={!checkClickWeekly}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="weekly_caps"
                    value={clickedCapsWeekly}
                    onChange={(e)=>{
                      setClicksCapsWeekly(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="click_capsMonthly"
                    onChange={() => setCheckClickMonthly(!checkClickMonthly)}
                    class="custom-control-input"
                  />
                  <label
                    for="click_capsMonthly"
                    class="custom-control-label m-0 text-l"
                  >
                    Monthly
                  </label>
                </div>
                  <input
                  // disabled={!checkClickMonthly}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="monthly_caps"
                    value={clickedCapsMonthly}
                    onChange={(e)=>{
                      setClicksCapsMonthly(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="click_capsGlobal"
                    onChange={() => setCheckClickGlobal(!checkClickGlobal)}
                    class="custom-control-input"
                  />
                  <label
                    for="click_capsGlobal"
                    class="custom-control-label m-0"
                  >
                    Global
                  </label>
                </div>
                <input
                // disabled={!checkClickGlobal}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="global_caps"
                    value={clickedCapsGlobal}
                    onChange={(e)=>{
                      setClicksCapsGlobal(e.target.value)
                    }}
                  />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Conversion Caps</label>
            <div class="caps-row">
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="conversion_capsDaily"
                    onChange={() => setCheckConvDaily(!checkConvDaily)}
                    class="custom-control-input"
                  />
                  <label
                    for="conversion_capsDaily"
                    class="custom-control-label m-0"
                  >
                    Daily
                  </label>
                </div>
                <input
                // disabled={!checkConvDaily}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="daily_caps"
                    value={convCapsDaily}
                    onChange={(e)=>{
                      setConvCapsDaily(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="conversion_capsWeekly"
                    onChange={() => setCheckConvWeekly(!checkConvWeekly)}
                    class="custom-control-input"
                  />
                  <label
                    for="conversion_capsWeekly"
                    class="custom-control-label m-0"
                  >
                    Weekly
                  </label>
                </div>
                <input
                // disabled={!checkConvWeekly}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="weekly_caps"
                    value={convCapsWeekly}
                    onChange={(e)=>{
                      setConvCapsWeekly(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="conversion_capsMonthly"
                    onChange={() => setCheckConvMonthly(!checkConvMonthly)}
                    class="custom-control-input"
                  />
                  <label
                    for="conversion_capsMonthly"
                    class="custom-control-label m-0 text-l"
                  >
                    Monthly
                  </label>
                </div>
                <input
                // disabled={!checkConvMonthly}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="monthly_caps"
                    value={convCapsMonthly}
                    onChange={(e)=>{
                      setConvCapsMonthly(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="conversion_capsGlobal"
                    onChange={() => setCheckConvGlobal(!checkConvGlobal)}
                    class="custom-control-input"
                  />
                  <label
                    for="conversion_capsGlobal"
                    class="custom-control-label m-0"
                  >
                    Global
                  </label>
                </div>
                <input
                // disabled={!checkConvGlobal}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="global_caps"
                    value={convCapsGlobal}
                    onChange={(e)=>{
                      setConvCapsGlobal(e.target.value)
                    }}
                  />
              </div>
            </div>
          </div>
          <div class="form-group">
            <label>Payout Caps</label>
            <div class="caps-row">
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="payout_capsDaily"
                    onChange={() => setCheckPayoutDaily(!checkPayoutDaily)}
                    class="custom-control-input"
                  />
                  <label
                    for="payout_capsDaily"
                    class="custom-control-label m-0"
                  >
                    Daily
                  </label>
                </div>
                <input
                // disabled={!checkPayoutDaily}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="daily_caps"
                    value={payoutCapsDaily}
                    onChange={(e)=>{
                      setPayoutCapsDaily(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="payout_capsWeekly"
                    onChange={() => setCheckPayoutWeekly(!checkPayoutWeekly)}
                    class="custom-control-input"
                  />
                  <label
                    for="payout_capsWeekly"
                    class="custom-control-label m-0"
                  >
                    Weekly
                  </label>
                </div>
                <input
                // disabled={!checkPayoutWeekly}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="weekly_caps"
                    value={payoutCapsWeekly}
                    onChange={(e)=>{
                      setPayoutCapsWeekly(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="payout_capsMonthly"
                    onChange={() => setCheckPayoutMonthly(!checkPayoutMonthly)}
                    class="custom-control-input"
                  />
                  <label
                    for="payout_capsMonthly"
                    class="custom-control-label m-0 text-l"
                  >
                    Monthly
                  </label>
                </div>
                <input
                // disabled={!checkPayoutMonthly}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="monthly_caps"
                    value={payoutCapsMonthly}
                    onChange={(e)=>{
                      setPayoutCapsMonthly(e.target.value)
                    }}
                  />
              </div>
              <div class="caps-item">
                <div class="custom-control custom-checkbox small mt-1">
                  <input
                    type="checkbox"
                    id="payout_capsGlobal"
                    onChange={() => setCheckPayoutGlobal(!checkPayoutGlobal)}
                    class="custom-control-input"
                  />
                  <label
                    for="payout_capsGlobal"
                    class="custom-control-label m-0"
                  >
                    Global
                  </label>
                </div>
                <input
                // disabled={!checkPayoutGlobal}
                    type="number"
                    placeholder="0.00"
                    className="form-control"
                    name="global_caps"
                    value={payoutCapsGlobal}
                    onChange={(e)=>{
                      setPayoutCapsGlobal(e.target.value)
                    }}
                  />
              </div>
            </div>
          </div>
          </Collapse>
        </div>
        <div className="form-panel offer">
          <div className="form-panel offer">
        <div className="form-submit-bar">
							<div>
								<Link
									to={"/admin/offers"}
									className="btn btn-sm btn-light text-muted mr-2 px-3"
								>
									Cancel
								</Link>
								<button
									type="submit"
									className="mr-2 px-3 btn btn-sm btn-submit"
									disabled={AddLoading}
								>
									{AddLoading ? "Adding..." : "Add"}
								</button>
							</div>
						</div>
      </div>
        </div>
      </form>
    </div>
  );
}

export default CustomOffer;
